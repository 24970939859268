import './skip-link-focus-fix';
import 'script-loader!slick-carousel';
import 'script-loader!lity';
import Stickyfill from 'stickyfilljs';

(function($) {

	$(document).ready(function () {
		mobileNav();
		articleGallery();
		processZoneChildrenDropdown();
		supplierDirectoryProcessZone();
		categoryFilter();
		enewsletterSignup();
		stickySkyscrapers();
	});

	// The sticky skyscraper columns
	function stickySkyscrapers() {
		var skyscrapers = $('.skyscraper-column');
		Stickyfill.add(skyscrapers);

		skyscrapers.each(function() {
			var lower  = $(this).find('.skyscraper-column__skyscraper--lower'),
			    height = $(this).height();
			if(!lower.is(':empty')) {
				$(this).css('top', -1 * ( height - 678 ));
			}
		});

		var sticky = $('.sticky');
	}

	// Single article image slider
	function articleGallery() {
		var slider   = $('.single-article__gallery-slider'),
		    captions = $('.single-article__gallery-captions');

		slider.slick({
			asNavFor: '.single-article__gallery-captions',
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: true,
			speed: 300,
			autoplay: false,
			infinite: true,
			adaptiveHeight: true
		});

		captions.slick({
			asNavFor: '.single-article__gallery-slider',
			infinite: true,
			speed: 200,
			fade: true,
			arrows: false,
			adaptiveHeight: true
		});
	}

	// Functionality relating to the mobile header/nav
	function mobileNav() {
		$('#search-toggle').on('click', function(){
			$('#mobile-search-form').toggleClass('site-header__mobile-search--open');
		});

		$("#mobile-category-dropdown").on('change', function(){
			location.href = $(this).val();
		});

		$('#menu-toggle').on('click', function(){
			$('#mobile-overlay').toggleClass('site-header__mobile-overlay--show');
			$('#mobile-menu').toggleClass('site-header__mobile-menu--open');
			$('body').toggleClass('no-scroll');
		});

		$('#mobile-overlay').on('click', function(){
			$(this).removeClass('site-header__mobile-overlay--show');
			$('#mobile-menu').removeClass('site-header__mobile-menu--open');
			$('body').removeClass('no-scroll');
		});
	}

	// Toggle dropdown of Process Zone children
	function processZoneChildrenDropdown() {
		$('.process-zone__children-toggle').on('click', function(e) {
			e.preventDefault();
			
			var dropdown = $(this).next('.process-zone__children-dropdown');

			dropdown.slideToggle();
			$(this).toggleClass('process-zone__children-toggle--open');
		});
	}

	// Supplier directory process zone filter
	function supplierDirectoryProcessZone() {
		var parentZone = '';
		var submittedZone = $('#wpdk_search_taxonomy').data('submitted-zone');
		
		$('#wpdk_search_terms > option:gt(0)').each(function(){
		  var value = $(this).val();
		  if($(this).text().indexOf("– ")==-1 && parentZone!=value)
		  {
			parentZone = value;
			  $('#wpdk_search_taxonomy').after('<form class="page-supplierdirectory__subzone-form" name="wpdk_search_taxonomy" action="/supplier-directory/?search=results" method="post" id="form-' + parentZone + '"><input type="hidden" name="wpdk_form_submit" id="wpdk_form_submit" value="wpdk_search_taxonomy"><p>Choose a Process Zone from the sub-categories below</p><select name="wpdk_search_terms" id="sub-zone-select-' + parentZone + '" class="sub-process-zone"><option></option></select><input type="submit" class="fandm-button page-supplierdirectory__submit" value="Go" /></form>');
		  }
		  else{

			  $('#sub-zone-select-'+parentZone).append($(this));

			  if( value == submittedZone) {
				$('#sub-zone-select-'+parentZone).val(value);
			}
			}  
		});
		$('.page-supplierdirectory__subzone-form').filter(function(){
			return $(this).children().length ==0;
		}).remove();

		$('#wpdk_search_terms').change(function(){
		   $('.page-supplierdirectory__subzone-form').hide();
		   $('#form-'+$(this).val()).css('display', 'flex');
		});

		$('#wpdk_search_terms').change();
	}

	// Category archive Process Zone filter
	function categoryFilter() {
		var parentZone = '';
		var submittedZone = $('#category_filter').data('submitted-zone');
		
		$('#category_filter > option:gt(0)').each(function(){
			var value = $(this).val();
			if($(this).text().indexOf("— ")==-1 && parentZone!=value) {
				parentZone = value;
				$('.category-page__zone-filter').after('<div class="category-page__subzone" id="subzone-' + parentZone + '"><p>Choose a Process Zone from the sub-categories below</p><select name="wpdk_search_terms" id="sub-zone-select-' + parentZone + '" class="sub-process-zone"><option></option></select><button class="fandm-button category-page__filter-submit">Go</button></div>');
			}
			else {
				$('#sub-zone-select-'+parentZone).append($(this));

				if( value == submittedZone ) {
					$('#sub-zone-select-'+parentZone).val(value);
				}
			}
		});
		$('.category-page__subzone').filter(function(){
			return $(this).children().length ==0;
		}).remove();

		$('#category_filter').change(function(){
		   $('.category-page__subzone').hide();
		   $('#subzone-'+$(this).val()).css('display', 'flex');
		});

		$('#category_filter').change();

		$(".category-page__filter-submit").on( 'click', function(){
			var slug = $(this).prev().find("option:selected").val();
			if (slug != '') location.href = window.location.pathname + '?filter=' + slug;
			else location.href = window.location.pathname;
		});
	}

	// e-Newsletter Signup Form
	function enewsletterSignup() {
		$("#enewsletter-state-non-usa").hide();

		$("#enewsletter-country").on('change', function() {
			var val = $(this).val();
			console.log(val);
			if ( val == 'United States' ) {
				$("#enewsletter-state-non-usa").hide();
				$("#enewsletter-state").show();
			}
			else {
				$("#enewsletter-state-non-usa").show();
				$("#enewsletter-state").hide();
			}
		});	
	}

	// When the user scrolls the page, execute myFunction
	window.onscroll = function() {fixedHeader()};

	// Get the header
	var header = document.getElementById("navBox");
	var header2 = document.getElementById("masthead");

	// Get the offset position of the navbar
	var sticky = header.offsetTop;

	// Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
	function fixedHeader() {
	  if (window.pageYOffset > sticky) {
	    header.classList.add("sticky");
	    header2.classList.add("sticky");
	  } else {
	    header.classList.remove("sticky");
	    header2.classList.remove("sticky");
	  }
	}

})(jQuery);